<template>
  <div>
    <b-row>
      <b-col
        md="6"
      >
        <b-card class="py-1">
          <b-list-group>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
              :to="{ name: 'orders-list-filtered', params:{ filter: 'new' } }"
            >Novos pedidos
              <b-badge
                variant="success"
                pill
              >
                {{ createdCount }}
              </b-badge>
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
              :to="{ name: 'orders-list-filtered', params:{ filter: 'paid-or-reserved' } }"
            >Pagos ou reservados
              <b-badge
                variant="primary"
                pill
              >
                {{ paidOrReserved }}
              </b-badge>
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
              :to="{ name: 'orders-list-filtered', params:{ filter: 'sent' } }"
            >Enviados
              <b-badge
                variant="secondary"
                pill
              >
                {{ sent }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-card>

      </b-col>
      <b-col
        md="6"
      >
        <b-card class="py-1">
          <h3>Favoritos</h3>
          <b-list-group>
            <b-list-group-item
              v-for="product in $store.state.dashboard.favorites"
              :key="product.id"
              class="d-flex justify-content-between align-items-center"
            >{{ product.name }}
              <b-badge
                variant="success"
                pill
              >
                {{ product.count }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
          <h3>Avise-me</h3>
          <b-list-group>
            <b-list-group-item
              v-for="product in $store.state.dashboard.notifyMe"
              :key="product.id"
              class="d-flex justify-content-between align-items-center"
            >{{ product.name }}
              <b-badge
                variant="success"
                pill
              >
                {{ product.count }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>

        </b-card>

      </b-col>
    </b-row>
    <b-card
      no-body
      class="card-revenue-budget"
    >
      <b-row class="mx-0">
        <b-col
          md="12"
          class="revenue-report-wrapper"
        >
          <div class="d-sm-flex justify-content-between align-items-center mb-3">
            <h4 class="card-title mb-50 mb-sm-0">
              Vendas mensais
            </h4>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center mr-2">
                <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
                <span>Vendas</span>
              </div>
              <div class="d-flex align-items-center">
                <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
                <span>Produtos</span>
              </div>
            </div>
          </div>

          <!-- chart -->
          <vue-apex-charts
            id="revenue-report-chart"
            type="bar"
            height="230"
            :options="chartOptions"
            :series="series"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BBadge,
    BListGroup,
    BListGroupItem,
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      total: 0.00,
    }
  },
  computed: {
    createdCount() {
      return this.$store.state.dashboard.ordersCount.CREATED ? this.$store.state.dashboard.ordersCount.CREATED : 0
    },
    paidOrReserved() {
      const paid = this.$store.state.dashboard.ordersCount.PAID ? this.$store.state.dashboard.ordersCount.PAID : 0
      const reserved = this.$store.state.dashboard.ordersCount.RESERVED ? this.$store.state.dashboard.ordersCount.RESERVED : 0
      return paid + reserved
    },
    sent() {
      return this.$store.state.dashboard.ordersCount.SENT ? this.$store.state.dashboard.ordersCount.SENT : 0
    },
    chartOptions() {
      const { monthFormater, reaisFormater } = this
      return {
        chart: {
          type: 'bar',
          toolbar: { show: false },
        },
        grid: {
          // padding: {
          //   top: -20,
          //   bottom: -10,
          // },
          // yaxis: {
          //   lines: { show: false },
          // },
        },
        xaxis: {
          // type: 'datetime',
          categories: this.$store.state.dashboard.monthlySales.map(item => monthFormater.format(new Date(item.month))),
          labels: {
            style: {
              // colors: '#b9b9c3',
              fontSize: '0.86rem',
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          formatter(val, opt) {
            if (opt.seriesIndex === 0) {
              return reaisFormater.format(val)
            }
            return val
          },
          enable: true,
          style: {
            // fontSize: '14px',
            // fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#ffffff'],
          },
        },
        colors: [$themeColors.primary, $themeColors.warning],
        plotOptions: {
          bar: {
            // columnWidth: '17%',
            endingShape: 'rounded',
          },
          distributed: true,
        },
        yaxis: [
          {
            max(max) {
              return max + 100
            },
            min: 0,
            forceNiceScale: true,
            labels: {
              formatter(val, opt) {
                return reaisFormater.format(val)
              },
              style: {
                // colors: '#b9b9c3',
                fontSize: '0.86rem',
              },
            },
          },
          {
            max(max) {
              return max + 100
            },
            min: 0,
            forceNiceScale: true,
            labels: {
              formatter(val, opt) {
                return val
              },
              style: {
                // colors: '#b9b9c3',
                fontSize: '0.86rem',
              },
            },
            opposite: true,
          },
        ],
      }
    },
    series() {
      const { reaisFormater } = this
      return [
        {
          name: 'Vendas',
          data: this.$store.state.dashboard.monthlySales.map(item => Number.parseFloat(item.final_value).toFixed(0)),
        },
        {
          name: 'Produtos',
          data: this.$store.state.dashboard.monthlySales.map(item => Number.parseFloat(item.product_number).toFixed(0)),
        },
      ]
    },
  },
  beforeCreate() {
    this.reaisFormater = new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumSignificantDigits: 5,
    })
  },
  created() {
    this.monthFormater = new Intl.DateTimeFormat('pt-br', {
      hour12: false,
      month: 'short',
      timeZone: 'UTC',
    })
    this.$store.dispatch('dashboard/getFullDashboard')
    if (this.$store.state.user.userData.user_id) {
      this.$posthog.identify(this.$store.state.user.userData.user_id, { email: this.$store.state.user.userData.user_id })
    }
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
